import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Guides from '../components/Guides'
import SEO from '../components/SEO'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import image from '../../content/images/irene_1.png';
import image1 from '../../content/images/irene_2.png';
import image2 from '../../content/images/irene_3.png';

import image3 from '../../content/images/nora.png';
import image4 from '../../content/images/russel.png';
import image5 from '../../content/images/krassi.png';
import image6 from '../../content/images/bastian.png';
import image7 from '../../content/images/tim_rohde.png';
import image8 from '../../content/images/eric_bruk.png';

/* const customRenderThumb = (children) =>
  children.map((item) => {
    const videoId = getVideoId(item.props.url);
    return <img src={getVideoThumb(videoId)} />;
  }); */

export default function About({ ...props }) {


  return (

    
    <Layout>
      <div className="carousel-about" >

        <Carousel>
          <div>
            <img className="carousel-image" src={image}  />
            <p className="legend">Climbing Park near Berlin</p>
          </div>
          <div>
            <img className="carousel-image" src={image1}  />
            <p className="legend">Berlin</p>
          </div>
          <div>
            <img className="carousel-image" src={image2}  />
            <p className="legend">Time festival near Berlin</p>
          </div>
        </Carousel>
      </div>

          

      <div className="about-section">
        <section>
          <div className="container">
            <header>
              <div className="container medium text-center">
                <h1 className="aboutTitle">About me 👩🏻‍💻</h1>
                <p className="subtitle medium text-center">
                  {/**Overview of my studies and work.*/}
                </p>
              </div>
            </header>


            <main>


              <div className="container medium">

                <p> 👋 Hey! Welcome to my page<b> I am Irena Popova.</b> A web developer and ambassador at Digital Career Institute.  I have managed to build <a href="https://github.com/irenapopova">some projects</a> and while learning I was having fun as well.  I went through a successful career change in 2016. 
                 </p>

                <p>Due to my strong desire to learn to code I used all the opportunities that I met down the road. Apart my daily coding classes I was attending twice a week JavaScript evening classes at ReDi school for Digital Integration and Open Tech School Berlin. It was intensive but I was super excited to learn and to boost further my knowledge. A really good  feature of my personality is that I don’t give up easily, and once I start a task I will find a solution regardless the difficulties I may face and the time it takes.
                  </p>

                  <h2>What keeps me motivated? 🤔</h2>
                  <p>
                  There are a few reasons that keep me driven to code and does not allow me to give up.
                 <p> 
                  On the first place is creating something, constructing new things every day. Each line of code is a different building block. Watching the product evolve with each feature is exciting. In a way I feel like a builder not engineer.
                  
                  Learning new stuff and evolving, in a team learning from each other is inspiring.  Coding actually involves endless learning. The process of self-improvement is very gratifying. Every day I feel a bit smarter than the day before.
                  In comparison with a video game, I am gaining experience points every day, and levels with each new thing I learn.
                  Making a work of Art also motivates me.
                  </p>
                  <p>   
                    Challenges I face also boost my motivation and I feel inspired from exploring a new territory. I feel like constantly thrown into the water, having to learn how to swim all over again. It can be difficult to face challenges so often, but it is also interesting. The bigger the challenge, the more interesting it is. And it is also very rewarding when I get over it.
                    The continuous process of getting things done is also  very motivating because as a developer I constantly build or fix stuff and make daily progress.
                  </p>

                  As I learn more on the company s domain and my specific tasks and technology, I am gradually improving my value. Belief in the Product also motivates me. I do not learn to code, I code to learn.
                   
                  <li> The idea to improve and further develop this blog came while organizing workshops as an Ambassador for the new students at Digital
                    Career Institute - Berlin, Germany.</li>
                  </p>
                  
                <p>If there is anything you want to talk about with me feel free to drop me a line at <strong>irenejpopova6</strong>@<strong>gmail.com</strong>. (<em> For educational purpose.</em>)</p>
                <p>Aside from coding, I enjoy biking, hiking, camping, dancing and listening to music, beer and coffee of course are in the list. I also like strategy games and chess. </p>

              </div>
              {/**===============My Philosophy and Belief =====================*/}
              <div className="container medium ">
                <h2>Philosophy</h2>
                <h5>My coding philosophy & teaching.</h5>
                
                <p>I have been fortunate to have been taught by many great teachers as a student, and to have co-taught with several outstanding teachers which helped me to further develop my skills in both fields - Applied Linguistics & Computer Science. 
               
              Based on those experiences, there are certain characteristics I feel are essential of being successful and these include - Respectful, Empathetic, Knowledgeable, Humorous, Passionate, Motivational, Organized, Creative, Challenging, Curious.


              Since I believe strongly in the notion of pursuing your passion, I feel that one of my roles as a coach is to help students discover and pursue their passions.
                </p>

                <p> Coding is the key to social mobility.
There is no skill more important in the 21st century than coding. It’s the way the world operates, and those who know how to do it, will have the competitive edge. </p>

                <p>
                  Real coding education is a marathon, not a sprint.
I have developed a curriculum to ensure students aren’t just introduced to the world of computer science but that they have the support and guidance to develop their coding skills with mentorship and personalized lessons.</p>

              </div>

              {/**=====Timeline / Chronology Education and work ================*/}

              <div className="container medium">
                <h2>Education and Work </h2>
                {/**<p>Overview of my Educational & work  path</p>*/}
                <ul>
                  <li><strong>1992 – 1993</strong>  I get my first PC running on DOS & Windows. The first computer language was <a href="https://en.wikipedia.org/wiki/Pascal_(programming_language)"> Pascal. </a> </li>

                  <li><strong>1993 – 1998</strong> Applied Linguistics - Master Degree.
                  </li>
                  <li><strong>2017 – 2019</strong> Software Engineering <a href="https://softuni.org/"> Soft-Uni</a> Distance Learning</li>
                  <li><strong>2019 -2020</strong> ReDi Shool fo Digital Integration, Berlin, Germany - Evening Classes: Advanced React & Javascript Courses</li>


                  <li><strong>2019 -2021</strong>  Web Development at <a href="https://www.digitalcareerinstitute.org/courses/web-development-course"> Digital Career Institute</a>, Berlin, Germany </li>
                  {/* <li><strong>2017 - 2019</strong>: I make a website about <a href="https://www.aboutme.com/">Se</a>,nnnnnn</li> */}

                  <h2>Recent Work </h2>
                  <li><strong> September 2019 – May 2021</strong>: Web Development Student & Ambassador at DCI</li>
                  <li><strong>May 2021 – Present</strong>: Majorel - Google AdWords, Berlin, Germany</li>
                </ul>
              </div>

              {/**Workshops Topics */}
              <div className="container medium">

                <h2>Workshops & Presentations </h2>
                <p>A list of some of the articles I have written outside of this website.</p>
                <ul>
                  <li><a href="https://dev.to/irenejpopova/objects-and-prototypes-in-javascript-2eie">Objects and
                      Prototypes in JavaScript (Article)</a> – <em>DEV, 2020</em></li>
                  <li><a
                    href="https://www.dev.com/community/tutorial_series/understanding-the-dom-document-object-model">
                  the DOM — Document Object Model (Tutorial)</a> – <em>DEV </em></li>


                  <li><a href="https://github.com/DCI-TechEd/Coding-Workshops">
                  Coding Workshops lessons and materials</a> – <em>DCI-TechEd, 2020</em></li>

                  <li><a href="https://github.com/DCI-TechEd/Coding-Workshops/blob/master/JS-Workshops/constructor.md">JavaScript Workshop - Constructor</a> – <em>DCI-TechEd, 2020</em></li>
                </ul>

              </div>

              <div className="container medium">
                <h2> Learning Lab </h2>
                <p>Those who are curious and want to explore more check the Learning Lab Tutorials and Exercises, more coming soon.</p>
                <ul>
                  <li><a href="https://github.com/CodeCrunchies">Learning Lab</a></li>

                </ul>

              </div>

              <div className="container medium">
                <h2> Links</h2>
                <ul>
                  <li> <a href="https://dev.to/irenejpopova">DEV</a></li>
                </ul>
              </div>
{/* Certificates*/}
              <div className="container medium">
                <h2> Curriculum Vitae</h2>

                <ul>
                  <li> <a href="/Irena_Popova_CV.pdf">Curriculum vitae</a></li>
                </ul>
{ /**  
                <ul>
                  <li> <a href="/Zertifikat_Popova_Irena.pdf">Digital Career Institute Certificate</a></li>
                </ul>
                <ul>
                  <li> <a href="/certificate-udacity.pdf">Front-End Web Developer NanoDegree - Udacity</a></li>
                </ul>

                <ul>
                  <li> <a href="/full-stack.pdf">Full Stack Webdeveloper NanoDegree - Udacity</a></li>
                </ul>

                <ul>
                  <li> <a href="/reactnano-udacity.pdf">React Developer NanoDegree - Udacity</a></li>
                </ul>

                <ul>
                  <li> <a href="/data_visualisation.png">Data Visualisation - FreeCodeCamp</a></li>
                </ul>

                <ul>
                  <li> <a href="/frontend_libraries.png">Front End Libraries - FreeCodeCamp</a></li>
                </ul>

                <ul>
                  <li> <a href="/apis-microservices.png">APIs and Microservices- FreeCodeCamp</a></li>
                </ul>

                <ul>
                  <li> <a href="/frontend_legacy.png">Legacy Front End - FreeCodeCamp</a></li>
                </ul>
*}

                {/** Memberships  */}
                <div className="container medium">
                  <h2>Memberships & Voluntary Work</h2>
                  <ul>
                    <li> <a href="https://www.redi-school.org/">ReDi School for Digital Integration - Berlin</a></li>
                    <li> <a href="http://wtmberlin.com/">WTM - (Women Tech Makers) Berlin</a></li>
                    <li> <a href="https://coderbee.de/">Coder Bee</a></li>
                    <li> <a href="https://gdg.community.dev/u/m5cq78/#/about">GOOGLE Developer Groups</a></li>
                    <li> <a href="https://gdg.community.dev/u/m5cq78/#/about">WTM Ambassador</a></li>
                  </ul>
                </div>
              
                
              </div>

              {/**Testimonials - second carousel */}

              <div className="container medium">
                <h2> Testimonials</h2>
              </div>

              <div className="testimonials" >
                <Carousel
                className="testimonial-root"
                  showArrows={true}
                  infiniteLoop={true}
                  showThumbs={false}
                  showStatus={false}
                  autoPlay={true}
                  interval={6100}
                >
                  <div className="testimonialSlide">
                    <img className="testimonialImage" src={image6} />
                    <div className="myTestimonial">
                      <h3>Bastian Albers</h3>
                      <h4>Software Engineer - Civey & OpenTechSchool coach Berlin </h4>

                      <p>
                      Irena is passionate about writing code and solving problems through the development of creative and innovative software. She is friendly, easy to get along with, well-liked by our students. Irena’s energy is limitless and her enthusiasm serves to motivate everyone in our study group. 
                      She is able to thoroughly focus on every project and is not easily distracted. She comprehends instructions quickly and is flexible when changes have to be made in the original plan. She wishes to move to a company with more challenging assignments and room for career growth, and I support her in her quest to move up the career ladder.

            </p>
                    </div>
                  </div>

                  <div className="testimonialSlide">
                    <img className="testimonialImage" src={image3} />
                    <div className="myTestimonial">
                      <h3>Nora Schiming</h3>
                      <h4>SEO CodeDoor - Frankfurt, Germany </h4>

                      <p>
                      Irena is a CodeDoor alumna who finished three courses. She takes her work very seriously, is a fast learner and as careful as she is cautious about her work. She repeatedly runs checks on her projects before making them public to ensure there aren’t any hiccups. Irena has a creative mind to use to come up with alternatives while building a project and facing issues. Her quick thinking and cautious personality can help a company to avoid some costly and embarrassing mistakes. With the right guidance and further training, I have no doubt, Irena could excel fast at an upper level in a web development position.
                      She is a valuable asset.
                    </p>
                    </div>
                  </div>

                  <div className="testimonialSlide">
                    <img className="testimonialImage" src={image4} />
                    <div className="myTestimonial">
                      <h3>Russel Snyder</h3>
                      <h4>Software Engineer - Method Park </h4>
                      <p>
                      It was refreshing to meet Irena at a Hacktober event in Berlin! She was an inspiration and reminder that learning is an endless journey and that it is never too late to become a programmer.

                      While working on some features for her open source portfolio projects, Irena showed great enthusiasm to learn software industry standards and frontend development in depth. I found Irena to be a natural communicator, quick learner and I believe she has potential to be a great developer.I recommend Irena to any group looking for a developer who can learn fast and grow with a team.
                    </p>
                    </div>
                  </div>

                  <div className="testimonialSlide">
                    <img className="testimonialImage" src={image8} />
                    <div className="myTestimonial">
                      <h3>Eric Bolikowski</h3>
                      <h4>Senior Full-Stack Developer,React Trainer & CTO - Binary Lights  Berlin, Germany</h4>
                      <p>
                        Irena was my student at ReDi School enrolled in the REACT course.  She showed great interest, motivation & passion throughout the training. During the React course she showed creativity, motivation, sense of humour and eagerness to learn. She is very goal oriented as well as a team player she was always easygoing. Irena was keenly devoted to the project, but being a down-to-earth person, was also enjoyable to be around. 
                       </p>
                    </div>
                  </div>


                  <div className="testimonialSlide">
                    <img className="testimonialImage" src={image5} />
                    <div className="myTestimonial">
                      <h3>Krassimir Popov</h3>
                      <h4>DevOPS, Cloud Architect - FlixBus, Munich</h4>
                      <p>
                      Irena enjoys coding and she is regularly enrolled in classes to stay abrest of the newest developments in those areas of programming where her interested is. She is a talented,creative,dependable and hard-working person with solid problem solving and tech skills. I strongly recommend her for any mid-level development position. With the right guidance and training, I have no doubt she will excell in upper-level dev position.
                       </p>
                    </div>
                  </div>

                  <div className="testimonialSlide">
                    <img className="testimonialImage" src={image7} />
                    <div className="myTestimonial">
                      <h3>Tim Rohde</h3>
                      <h4>Marketing & Head of Ambassador Management - Digital Career Institute, Berlin</h4>
                      <p>
                        Irena organised her own JavaScript workshop in a three-part series for DCI marketing department. 
                        She shared her knowledge about objects, functions, strings and more and helped more than 60 people who participated in the workshop series to understand JavaScript. During the workshop series, she was able to market herself as a professional educator and expert in HTML, CSS and JavaScript. She showed great motivation, creativity and responsibility throughout the process and helped us generate qualified new participants for our courses. She was the pioneer for our very successfull ambassador program and has brought great value to our company. It was a pleasure to work with her!
                       </p>
                    </div>
                  </div>

                </Carousel>
              </div>

            </main>
          </div>
        </section>
      </div>

    </Layout>
  )
}